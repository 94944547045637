:root {
	--bg-color: #1f2937;
	--button-bg-color: #2563eb;
	--text-100: #f3f4f6;
	--text-200: #e5e7eb;
}

body {
	margin: 0;
	padding-bottom: 50px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--bg-color);
	color: var(--text-100);
	overflow-y: scroll;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

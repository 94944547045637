.mainContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 20px;
}

.dataContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 600px;
	margin: 0 20px;
}

.header {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 32px;
	font-weight: 600;
}

.header h1 {
	display: inline-block;
	font-size: inherit;
	margin: 0;
}

.header span {
	padding: 0 10px;
}

.bio {
	text-align: center;
	color: var(--text-200);
	margin-top: 16px;
	margin-bottom: 32px;
}

.buttonGroup {
	margin: 10px 0;
	min-height: 46px;
	display: flex;
	gap: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.justifyCenter {
	justify-content: center;
	text-align: center;
}

.network {
	margin: 5px 0;
}

.networkName {
	text-transform: capitalize;
}

.networkValid {
	color: #6ee7b7;
	font-weight: 500;
}

.networkInvalid {
	color: #f87171;
	font-weight: 500;
}

.fading {
	animation: fadein 1s;
}

.disabled {
	opacity: 0.8;
	filter: grayscale(0.1);
	cursor: not-allowed;
}

.disabled * {
	pointer-events: none;
}

.button {
	cursor: pointer;
	padding: 8px 16px;
	border: 0;
	border-radius: 5px;
	font-size: 1rem;
	background-color: var(--button-bg-color);
	color: var(--text-100);
	text-decoration: none;
}

.button:hover {
	-webkit-filter: brightness(80%);
}

.button:active {
	transform: translate(1px, 1px);
}

.buttonNoWallet {
	--button-bg-color: #b91c1c;
}

.buttonMetaMask {
	--button-bg-color: #f6851b;
}

.dotConnected {
	display: inline-block;
	margin: 1px 10px;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #34d399;
}

.buttonWave {
	--button-bg-color: #fde68a;
	--text-100: #1f2937;
}

.buttonCake {
	--button-bg-color: #f472b6;
}

.buttonFire {
	--button-bg-color: #3b82f6;
}

.buttonEmoji {
	padding-right: 10px;
}

.waveStatus {
	margin: 20px 0;
	text-align: center;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media screen and (min-width: 768px) {
	.mainContainer {
		margin-top: 64px;
	}

	.buttonGroup {
		flex-direction: row;
	}
}

.wave {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 15px 10px;
	justify-content: flex-start;
	background-color: rgba(55, 65, 81, 0.8);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.reaction {
	position: absolute;
	top: 50px;
	right: 10px;
	padding: 0 10px;
	font-size: 1.5rem;
}

.body {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.body dl {
	display: flex;
	flex-direction: column;
}

.body dt {
	color: #6ee7b7;
	font-weight: 500;
	font-size: 0.9rem;
}

.body dd {
	font-size: 0.85rem;
	max-width: calc(100vw - 40px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

dl,
dt,
dd {
	margin: 0;
}

.message {
	margin-top: 10px;
}

@media screen and (min-width: 768px) {
	.wave {
		flex-direction: row;
	}

	.reaction {
		position: static;
		font-size: 2rem;
	}

	.body dl {
		display: grid;
		gap: 5px 10px;
		grid-template-columns: auto 1fr;
	}

	.body dt {
		font-size: 0.95rem;
	}

	.body dd {
		font-size: 0.9rem;
	}
}

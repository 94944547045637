.textWrapper {
	margin-bottom: 20px;
}

.textWrapper label {
	display: block;
	margin-bottom: 8px;
}

.textBox {
	display: block;
	box-sizing: border-box;
	padding: 5px;
	width: 100%;
	height: 80px;
	border: none;
	border-radius: 5px;
	font-family: inherit;
	font-size: 1rem;
}
